import React, { useState } from "react"
import styled from "styled-components"
import breakpoints from "../../../styles/breakpoints"
import fonts from "../../../styles/fonts"
import colors from "../../../styles/colors"
import { ButtonStyleDark } from "../../Core/Buttons/Styles"
import TextButton from "../../Core/Buttons/TextButton"
import { InputContainerStyle } from "../Styles"
import axios from "axios"
import { getUserErrorMessage } from "../../../services/core/accountFunctions"

const FormContainer = styled.div`
  ${fonts.garamondRegular};
  max-width: 423px;
  margin: auto;
  width: 100%;
`

const Title = styled.h1`
  ${fonts.garamondRegular};
  color: ${colors.charcoal};
  font-size: 32px;
  margin-top: .67em;
  margin-bottom: 30px;
  text-align: center;
  line-height: 1.15;

  @media (min-width: ${breakpoints.md}) {
    font-size: 40px;
    letter-spacing: normal;
  }
`

const InputContainer = styled.div`
  ${InputContainerStyle}
`

const SubmitButton = styled.button`
  ${ButtonStyleDark};
  max-width: unset;
`

const Navigations = styled.div`
  margin-top: 40px;
  display: grid;
  grid-gap: 30px;
  justify-content: center;
  text-align: center;

  a {
    letter-spacing: 1px;
    span {
      padding-bottom: 6px;
    }
  }
`

const RegisterButtonContainer = styled.div`
  display: grid;
`

const Claimer = styled.div`
  ${fonts.sofiaPro};
  margin-top: 4px;
  font-size: 12px;
`

const ServerError = styled.p`
  font-size: 14px;
  letter-spacing: .25px;
  color: #c00;
`

const RecoverForm = () => {
  const [email, setEmail] = useState("")
  const [submitText, setSubmitText] = useState("Recover Password")
  const [error, setError] = useState("")

  const handleSubmit = async (event) => {
    event.preventDefault()

    setSubmitText("Processing...")

    const url = '/.netlify/functions/shopifyUpdate'

    const data = {
      "mutationType": "recoverPassword",
      "data": {
        "email": email
      }
    }

    try {
      const response = await axios.post(url, data)

      if (response.data.response.customerRecover.customerUserErrors.length === 0) {
        setSubmitText("Email sent")
      } else {
        const errorMessage = getUserErrorMessage(response.data.response.customerRecover.customerUserErrors[0].message)
        setError(errorMessage)
        setSubmitText("Recover Password")
      }

    } catch {
      setError("Server error")
      setSubmitText("Recover Password")
    }

    
  }

  return (
    <FormContainer>
      <Title>Recover Password</Title>
      <form onSubmit={handleSubmit}>
        <InputContainer>
          <ServerError>{error}</ServerError>
        </InputContainer>
        <InputContainer>
          <label>Email</label>
          <input
            type="email"
            placeholder="Email"
            name="customer[email]"
            autoCapitalize="off"
            autoComplete="email"
            autoCorrect="off"
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </InputContainer>
        <SubmitButton type={"submit"}>{submitText}</SubmitButton>
      </form>
      <Navigations>
      <RegisterButtonContainer>
          <TextButton to="/account/register/">
            Register
          </TextButton>
          <Claimer>(For existing customers)</Claimer>
        </RegisterButtonContainer>
        <TextButton to="/account/">Login</TextButton>
      </Navigations>
    </FormContainer>
  )
}

export default RecoverForm
